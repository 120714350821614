import React, { useState, useRef } from "react"
import { Autocomplete, useLoadScript } from "@react-google-maps/api"
import styled from "styled-components"

import { useStateValue } from "../../../data/StateContext"
import { hex2rgba } from "../../../styles/tools"

const Wrapper = styled.div`
  position: relative;
  z-index: 2;

  @media (max-width: 900px) {
    margin-top: 10rem;
  }
  @media (max-width: 700px) {
    display: none;
  }

  h1 {
    display: block;
    margin-left: -0.5rem;
    padding: 0.2rem 0.5rem 0rem;

    background-color: ${props => props.theme.color.lightBg};
    border-radius: 6px;
  }
`

const Dots = styled.div`
  position: relative;

  margin-bottom: 1rem;
  width: 12px;
  height: 12px;
  border-radius: 12px;

  background-color: ${props => props.theme.color.primary};
  transition: background 0.2s ease-in-out;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    display: block;
    width: 12px;
    height: 12px;

    border-radius: 12px;
    background-color: ${props => props.theme.color.primary};
    transition: background 0.2s ease-in-out;
    content: "";
  }
  &::before {
    left: calc(100% + 8px);
  }
  &::after {
    left: calc(100% + 28px);
  }
`

const Form = styled.form`
  position: relative;
  display: inline-block;

  input[type="text"],
  button {
    display: block;

    font-size: 1.2em;
  }

  input[type="text"] {
    padding: 0.5rem 6rem 0.5rem 1rem;
    width: 360px;
    max-width: 100%;

    color: ${props => props.theme.color.primary};
    font-weight: bold;

    border: 3px solid ${props => props.theme.color.lightBg};
    border-radius: 200px;
    outline: none;
    box-shadow: 0 0 0 3px ${props => props.theme.color.lightBg},
      0 0 0 6px ${props => props.theme.color.primaryBackground};

    background-color: ${props => props.theme.color.lightBg};
    -webkit-appearance: none;

    transition: all 0.2s ease-in-out;

    &:focus,
    &:focus-within {
      box-shadow: 0 0 0 3px ${props => props.theme.color.lightBg},
        0 0 0 6px ${props => props.theme.color.primaryBackground},
        0 0 0 12px ${props => hex2rgba(props.theme.color.highlight, 0.75)};
    }

    &::placeholder {
      font-style: italic;
    }
  }
  button {
    position: absolute;
    right: 0;
    top: 0;

    padding: 0.5rem 1.25rem 0.5rem 0.75rem;
    height: 100%;

    cursor: pointer;
    border: none;
    border-radius: 0 200px 200px 0;
    background-color: ${props => props.theme.color.primaryBackground};
    transition: all 0.2s ease-in-out;

    box-shadow: -9px 0 0 0 ${props => props.theme.color.heroButtonBg1},
      -18px 0 0 0 ${props => props.theme.color.heroButtonBg2},
      -27px 0 0 0 ${props => props.theme.color.heroButtonBg3};

    font-weight: bold;
    text-transform: uppercase;
    color: ${props => props.theme.color.secondary};

    &:hover {
      color: #fff;
    }
  }
`

let geocoder = null
const libraries = ["places"]

export default function Hero(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDV5yvan953q_mlvPFtWuvZTSG38YSBFf4",
    libraries: libraries,
    preventGoogleFontsLoading: true,
  })

  const [{ finderLoadingResults }, dispatch] = useStateValue()

  const submitForm = e => {
    e.preventDefault()
    if (!finderLoadingResults) {
      onPlaceChanged()
    }
  }

  const [autocomplete, setAutocomplete] = useState(null)
  const onAutocompleteLoad = a => {
    setAutocomplete(a)
  }

  let inputRef = useRef(null)

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace()
      if (place && place.geometry) {
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()
        updatePlace(lat, lng)
        // console.log("Found a place", lat, lng)
      } else {
        const backupValue = inputRef && inputRef.value ? inputRef.value : ""
        const searchLocation = place && place.name ? place.name : backupValue
        // console.log("Geocode a place", searchLocation)
        geocodeLocation(searchLocation)
      }
    }
  }

  const geocodeLocation = location => {
    if (geocoder !== null) {
      geocoder.geocode({ address: location }, function(results, status) {
        if (status === "OK") {
          // console.log("GEOCODE SUCCESS!", results)
          const lat = results[0].geometry.location.lat()
          const lng = results[0].geometry.location.lng()
          updatePlace(lat, lng)
          // console.log("Geocoded!", lat, lng)
        }
      })
    } else {
      console.warn("NO GEOCODER!")
    }
  }

  const updatePlace = (latitude, longitude) => {
    dispatch({
      type: "getFinderResults",
      finderLoadingResults: true,
      finderSearchTerm: inputRef ? inputRef.value : "",
      finderSearchLat: latitude,
      finderSearchLng: longitude,
    })
  }

  const renderInput = () => {
    const newGeocoder = new window.google.maps.Geocoder()
    geocoder = newGeocoder

    return (
      <Wrapper>
        <Dots />
        <h1>Find a f'real near you!</h1>

        <Form onSubmit={e => e.preventDefault()}>
          <Autocomplete
            onLoad={onAutocompleteLoad}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type="text"
              placeholder="Enter your zip code..."
              onFocus={e => {
                e.target.value = ""
              }}
              ref={el => {
                inputRef = el
              }}
            />
          </Autocomplete>
          <button onClick={submitForm} type="button">
            Go!
          </button>
        </Form>
      </Wrapper>
    )
  }

  const wrapperElement = () => (
    <Wrapper>
      <Dots />
      <h1>Find a f'real near you!</h1>

      <Form onSubmit={e => e.preventDefault()}>
        <input
          type="text"
          placeholder="Enter your zip code..."
          readOnly={true}
        />
        <button onClick={e => e.preventDefault()} disabled type="button">
          Go!
        </button>
      </Form>
    </Wrapper>
  )

  return isLoaded ? renderInput() : wrapperElement()
}
